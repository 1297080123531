import React from "react";
import "./Slider2.css";
import { useInView } from "react-intersection-observer";
import app_screenshot01 from "../Assets/app_screenshot01.jpg";
import foresee_icon from "../Assets/logo.png";
import { useTranslation } from 'react-i18next'


const Slider2 = ({ imageSrc, title, subtitle, flipped }) => {
  const { t, i18n } = useTranslation();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  const renderContent = () => {
    if (!flipped) {
      return (
        <>
          <img src={foresee_icon} alt="Travel" className="slider2__image_01" />
          <div className="slider2__content">
            <h1 className="slider2__title">{t('slider2__title01')}</h1>
            <p>{t('0101')}</p>
            <p>{t('0102')}</p>             
            <span><br /></span> 
            <p> {t('0103')}</p>
          </div>

        </>
      );
    } else {
      return (
        <>
          <img src={app_screenshot01} alt="Travel" className="slider2__image_02" />
          <div className="slider2__content">
            <h1 className="slider2__title">{t('slider2__title02')}</h1>
            <p>{t('0201')}</p>
            <span><br /></span> 
            {/* new line */}
            <p>{t('0202')}</p>
          </div>
          {/* <div className="slider2__content">
            <h1 className="slider2__title">{title}</h1>
            <p>{subtitle}</p>
          </div>
          <img src={imageSrc} alt="Travel" className="slider2__image" /> */}
        </>
      );
    }
  };

  return (
    <div className={inView ? "slider2 slider2--zoom" : "slider2"} ref={ref}>
      {renderContent()}
    </div>
  );
};

export default Slider2;
