import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      'Welcome': 'Welcome to the website!',
      //Home Screen
      'Home__title': 'We are ForeSee',
      'Home__content1': 'A student-led organization from HKUST that is dedicated to',
      'Home__content2': 'spread awareness of eye health and vision protection',
      //'Home__follow': 'ForeSee - "Look into the bright future!"',

      //slider:home screen
      'slider2__title01': 'ForeSee - UST Student Organization',
      '0101': 'ForeSee is a student-initiated organization focusing on public eye health in Hong Kong.',
      '0102': 'We are dedicated to raising public awareness of vision care, especially for young children and their parents.',
      '0103': 'ForeSee was founded by a team of enthusiastic students from HKUST (Hong Kong University of Science and Technology) with the care for public eye health and passion for serving the HK local community. It is currently supported by the SIGHT program of HKUST and is actively initiating and involving in multiple eye-health-related projects and campaigns as a UST student organization.',

      'slider2__title02': 'ForeSee - Eye Care App',
      '0201': 'The ForeSee app is one project launched by the HKUST ForeSee team. It is one integrated eye-health-themed mobile application, aimed at providing a one-station solution for daily eye care. At the current stage, the app is mainly targeting at children aged between 3-12 and their parents in the Hong Kong area. Its function highlights include a dynamic eyesight trend graph, articles, and videos on eye health, Snellen chart for self-tests.',
      '0202': 'The app is now available in the Google Play Store. The iOS version is still under development, please stay tuned. ',


      //part3 of home screen
      'home_part3': 'Join us to get more out of it',

      'event_01': 'ForeSee Eye Care Classroom',
      'event_01_description': 'Listen to the eye care advice from professionals.',
      'event_02': 'Weekly Eye Care Post',
      'event_02_description': 'Interesting articles, facts, and surveys for eye care related topics!',
      'learn_more': 'Learn More',
      'WATCH_VIDEOS': 'Watch more',

      //part4 of home screen
      'home_part4': 'Our Team',
      '0401': 'Overall, ForeSee consists of 5 teams: App Development, Business Development, Finance, Human Resources, and Operations. On top of that, we have Kiara as CEO, Coco as COO, Jaman as Consultant to oversee daily operations and make decisions.',
      '0402': 'Inside the teams, starting with App Development, Alpha is leading the team with members including Jimmy, Jovan, Kamakshi, Monica, Oscar, and Wilson. For Business Development, Kendra is the head where Ahmed, Marvin and Julie are responsible for marketing; Abbie and Emily are responsible for public relations; Cathleen and Sankalp are responsible for website development. In the Finance team, Facundo is the CFO with members Amodth, Aryan, Dicky, Qiushi, and Steve. In Human resources, Chloris is the leader working with Kathy. As for Operations, Rita is the team head with David, Joyce, Karen, Rakshita, and Stephenie as team members.',


      //about: our story
      'our-story-title': 'Our Story',
      '0501': 'There is a lack of efficient eye data archiving and collecting centers in the eye professional industry in Hong Kong. Apart from that, an alarmingly increasing number of children in Hong Kong suffer from myopia, also known as short-sightedness. Seeing the above issues, we innovated an all-in-one eye care app, ForeSee, in the hope to prevent eye health degradation and to build habits on eye-healthcare routine.',
      '0502': 'Starting the project in 2020, we have successfully launched the 2.0 version of our comprehensive eye-care mobile app in Google Play Store and are currently working on the iOS version.',
      '0503': 'In addition to app development, we are also dedicated to other activities related to eye health, especially for children who are underprivileged. We are now working on the children’s eye checkup project to promote the idea of children’s regular eye check-ups among the parents to detect eye problems at an early stage and receive timely treatment. Besides, we organized online seminars with eye professionals and initiated a series of social media campaigns for spreading information about eye protection.',
      '0504': "We're still on the way to look for more ways to improve general eye health…",

      //about: our members
      'our-members-title': 'Our Members', 
      //Different member names, positions and their respective description 
      '0601_name': 'Abbie',
      '0601_position': 'Business Development Team',
      '0601_description': 'I like drawing and playing piano. I love to make new friends!',

      '0602_name': 'Ahmed',
      '0602_position': 'Business Development Team',
      '0602_description': 'I like hiking, playing and watching football, and exploring new places. I also enjoy cooking!',
      
      '0603_name': 'Alpha',
      '0603_position': 'Head of App Development Team',
      '0603_description': 'I am Alpha and through my time of leading the App development team, I am really proud of working with ForeSee starting from a small student project to a heartful charity aiming to serve the society.',
      
      '0604_name': 'Aryan',
      '0604_position': 'Finance Team',
      '0604_description': 'I enjoy watching movies and TV shows (consider myself a movie buff) and love playing Basketball, Table Tennis, and Badminton in my spare time.',
      
      '0605_name': 'Cathleen',
      '0605_position': 'Business Development Team',
      '0605_description': 'Hiii! cathleen here. fun fact about me, as you can see from the photo, i love wearing caps.',
      
      '0606_name': 'Chloris',
      '0606_position': 'Head of Human Resources Team',
      '0606_description': 'This is Chloris! One fact about me is that my laughing point is extremely low, so I tend to laugh a LOT. The atmosphere here in ForeSee is great, hope you’ll have a taste of it through joining our activities and campaigns!',
      
      '0607_name': 'Coco',
      '0607_position': 'Chief Operating Officer',
      '0607_description': "Really glad to be a part of ForeSee and it has definitely been a fruitful journey to me! If you open our app and try out the eye exercise function, you'll be able to hear my voice!",
      
      '0608_name': 'David',
      '0608_position': 'Operations Team',
      '0608_description': 'My name is David, a third-year local undergraduate majoring in Decision Analytics. The fun fact is my head is made of football, Japanese and movies :)',
      
      '0609_name': 'Dicky',
      '0609_position': 'Finance Team',
      '0609_description': 'I am an enthusiastic person, who is always glad to explore more in the business world.',
      
      '0610_name': 'Facundo',
      '0610_position': 'Chief Financial Officer',
      '0610_description': 'My name is Facundo Llamas, I am from Argentina. I am graduating this semester and a fun fact: I have lived in 8 countries.',
      
      '0611_name': 'Jaman',
      '0611_position': 'Consultant/Business Development Team',
      '0611_description': 'Hello~ I am Jaman from ForeSee! I love talking and I enjoy making friends from different background!😏 Fun Fact about me: I use social media only once per month😂',
      
      '0612_name': 'Jimmy',
      '0612_position': 'App Development Team',
      '0612_description': 'a boy that like mahjong and hiking',

      '0613_name': 'Jovan',
      '0613_position': 'App Development Team',
      '0613_description': 'I am from Indonesia and I like food, sleep, cycling and movies.',

      '0614_name': 'Joyce',
      '0614_position': 'Operations Team',
      '0614_description': 'Hi I’m Joyce, I am a senior member of the ForeSee Operations Team, a business student with an incredibly great passion for long-distance running and literature. Hungry to make a difference with the whole ForeSee team!',

      '0615_name': 'Julie',
      '0615_position': 'Business development Team',
      '0615_description': 'I love watching K-drama and playing mahjong.',

      '0616_name': 'Kamakshi',
      '0616_position': 'App Development Team',
      '0616_description': 'I am an organized, creative, detail-oriented out-of-the-box thinker. I have a growth mindset and consistently invest in my personal development through entrepreneurship programs, MUN conferences, social media courses. I am passionate about photography and love to watch movies, Harry Potter is one of my all-time favorites.',

      '0617_name': 'Kathy',
      '0617_position': 'Human Resources',
      '0617_description': 'I am an outgoing girl who likes working in the business field. Besides, I wish I could learn more through different job experiences.',

      '0618_name': 'Kendra',
      '0618_position': 'Head of Business Development Team',
      '0618_description': 'I am Kendra. I am responsible for the Business Development of ForeSee. In my view, ForeSee members are some of the most kindhearted and motivated people I have ever met. I feel so proud and happy to be part of the team.',

      '0619_name': 'Marvin',
      '0619_position': 'Business Development Team',
      '0619_description': 'Hi there! My hobbies include playing chess, gaming and playing the piano. I also like hiking but once I was so stuck in the middle of the hiking trail that I only managed to get back down at midnight (with almost no buses or vans to return to the urban area)!',

      '0620_name': 'Oscar',
      '0620_position': 'App Development Team',
      '0620_description': 'Like to learn new things and get along with people',

      '0621_name': 'Qiushi',
      '0621_position': 'Finance Team',
      '0621_description': 'My name is Qiushi and I am a fourth year WBB student. I work with ForeSee’s finance team. In my leisure time, I really enjoy contemporary dancing and I have a twin brother.',

      '0622_name': 'Rakshita',
      '0622_position': 'Operations Team',
      '0622_description': 'I enjoy reading mystery/fantasy novels, plus the occasional Netfix binge',

      '0623_name': 'Rita',
      '0623_position': 'Head of Operations Team',
      '0623_description': 'Hi! This is Rita from the operations team~ I am currently a year 3 student majoring in Economics and Finance. I spend most of my spare time on dramas and TV shows, but now I am trying to do more sports! It’s my pleasure to be part of ForeSee family and hope you can enjoy the journey with ForeSee!',

      '0624_name': 'Sankalp Verma',
      '0624_position': 'Business Development Team',
      '0624_description': 'My hobbies include watching netflix, eating, thinking about what to eat next and procrastination. My interests include trees.',

      '0625_name': 'Shafira Monica',
      '0625_position': 'App Development Team',
      '0625_description': "Hi, I'm Monica from the App Development team. I like traveling, watching movies, and cooking (a bread enthusiast)! Looking forward to bring some changes in ForeSee~",

      '0626_name': 'Stephanie',
      '0626_position': 'Operations Team',
      '0626_description': 'I love mysteries and suspenseful TV shows, and I do grocery shopping to manage stress~',

      '0627_name': 'Steve',
      '0627_position': 'Finance Team',
      '0627_description': 'I really love traveling around and have a North British accent.',

      '0628_name': 'Wilson',
      '0628_position': 'App Development Team',
      '0628_description': "Heyyo! Aside from being a programmer, I'm a big fan of music, sports, and travelling. If you have any interest in those stuffs, feel free to slide into my dm ;) .A bit fun fact about me is I have a perfect pitch, but not to the level of charlie puth xD.",

      '0629_name': 'Amodh',
      '0629_position': 'Finance Team',
      '0629_description': "Hi, I'm from India and am passionate about playing football. A fun fact about me would be that I can do a split",

      '0630_name': 'Karen',
      '0630_position': 'Operations Team',
      '0630_description': 'I am addicted to mahjong. I like traveling and experiencing different cultures.',
      
      '0631_name': 'Kiara',
      '0631_position': 'Chief Executive Officer',
      '0631_description': 'Really honoured to be a part of ForeSee and hope you like ForeSee as well! One thing about me: I’ve been keen on keeping a healthy sleep schedule but often failed to do so :)',
      
      // Appending for addition
      // '0632_name': '',
      // '0632_position': '',
      // '0632_description': '',
      



      //about: our mission
      'our-mission-title': 'Our Mission',
      '0701': 'We hope to empower local communities with the resources necessary to convey knowledge on eye care and prevent eye health degradation at an early stage.',
      
      //about: our partners
      'our-partners-title': 'Our Partners',
      '0801': '',
    

      //app: demo screen
      'app': 'App',
      'app-showcase-title1': 'Vision Tracking',  
      '0901': 'Children’s visual acuity can be recorded manually by professional optometrists or parents. The chart visually presents children’s vision trends, which allows parents to observe their children’s vision development and detect potential eye problems in the early stage.',   

      'app-showcase-title2': 'Eye Exercises', 
      '0902': 'Audio recorded eye exercises promote the daily habit of relieving eye strain. Children can relax their eyes from the use of consumer electronics by following the audio descriptions.',

      'app-showcase-title3': 'Q&A Forum', 
      '0903': 'Parents are welcomed to raise eye related questions and these questions will be addressed on a one-to-one basis by professionals.',

      'app-showcase-title4': 'Eye Care Tips', 
      '0904': 'Eye care tips are shared regularly, and content is composed originally by ForeSee members. Eye care content includes eye health insights, eye-benefitting recipes, and video interviews with professionals on eye care FAQs.',

      //events: i care eye care
      'title1': 'I Care Eye Care',
      '1001': 'To raise awareness of eye care, we invited Professor Leung and Professor Lam from the University of Hong Kong to give this lecture on World Sight Day. Professor Leung introduced the habits that damage eyesight, and Professor Lin gave the participants a better understanding of myopia. We will invite more ophthalmologists to share ophthalmology knowledge with you, please stay tuned for more information!',
      'read_more': 'READ MORE',

      //events: public eye check up 
      'title2': 'Public Eye Check-up Initiative',
      '1005': 'Good vision is essential to a child’s growth. Foresee believes that every child should have the right to receive proper eye care service, regardless of their financial situation. Therefore, we would like to launch an initiative that offers eye check service to children from 3 to 12 years old, and we would also prioritize underprivileged families.',
      '1006': 'Foresee will collaborate with qualified optometrists in Hong Kong to provide professional advice to children after each check-up. The service can help children to discover whether they are suffering from any eye illness or not. They could seek professional advice from the optometrist and have follow-up actions to control the situation.',
      '1007': 'Foresee aims to promote the idea of children’s regular eye check-ups among the parents, at the same time to safeguard children’s eye health by detecting eye illness at the early stage.',

      // events: eye health under covid-19
      'title3': 'Eye Health Under COVID-19',
      'subtitle1': 'Eye Health under COVID-19',
      'subtitle2': '#10-day Cross-Channel Initiative',
      'subtitle3' : 'Eye Professional Talk Series',
      '1008' : 'The prolonged existence of Covid-19 has worsened the situation as it brought an enormous difference to our everyday life. While less publicly mentioned, it is also impacting people’s eye health as people are spending much more time on digital screens.',
      '1009' : 'In view of this, ForeSee would like to organize a cross-channel social media campaign on Instagram, Facebook, and YouTube, aiming to help shed light on the current problem and actions to be taken.',
      '1010' : 'The campaign will last 10 days, with the presentation of the background information, explanations, guidance from ophthalmology professors, relevant tips and advice, a prize-granting quiz in the end, etc. Stay tuned and follow us on our social media to learn more about this exciting event!',
      'link' : 'FOLLOW US',
      '1011' : 'What’s the advice from optometrists and ophthalmology professors?',
      'link2' : 'WATCH MORE',


      //contact us
      'contact_us': 'Contact Us',
      '1101': 'Drop Us A Message',
      
      // Navibar translations
      // some of the below items are reused items later   
      'home': 'Home',
      'about': 'About',
      'App': 'App',
      // 'our-story-title': '我們的故事',
      // 'home_part4': '我們的團隊',
      // 'our-mission-title': '我們的使命',
      // 'our-partners-title': '我们的合作伙伴',
      // 'contact_us': 'Contact Us',
      'event': 'Event',
      // 'title1': 'I Care Eye Care',
      'language': 'Language',




    }
  },
  //Didn't apply zh_hk due to unknown bug
  ///////////////////////////////////////////////////////////
  //Traditional Chinese Translation is below
  'zh-TW': {
    translation: {
      'Welcome': '大家好！',
      //Home Screen
      'Home__title': '我們是ForeSee 團隊',
      'Home__content1': '一個科大學生主導的機構',
      'Home__content2': '致力於傳播眼睛健康和視力保護的意識',
      //'Home__follow': '>了解更多 > (我們的宗旨)  加入我們 > (加入我們)',

      //slider: home screen
      'slider2__title01': 'ForeSee - 一個科技大學學生組織',
      '0101': 'ForeSee 是一個以學生發起的組織，專注於香港大眾的眼部健康。',
      '0102': '其使命是提高公眾對視力保健的認識，尤其針對幼兒及其父母。',
      '0103': 'ForeSee 由一群來自香港科技大學 (HKUST) 的熱心學生組成，我們關心公眾的眼睛健康，並熱衷於貢獻和服務香港社會。 目前在香港科技大學SIGHT（視界無限）項目的支持下運作，並作為一個香港科技大學的學生組織積極發起和參與多個與眼部健康相關的項目和活動。',

      'slider2__title02': 'ForeSee - 一個眼部健康手機應用程式',
      '0201': 'ForeSee 應用程序是ForeSee 團隊推出的一個項目。它是一款以眼部健康為主題的手機應用程式，旨在為日常眼部保健提供一站式解決方案。 現階段，該應用主要針對香港地區3-12歲的兒童及其父母。 其功能亮點包括動態視力趨勢圖、關於眼睛健康的文章和視頻、用於自測的視力圖表。',
      '0202': '該應用程序現已在 Google Play 商店上架。 iOS版本還在開發中，敬請期待。',

      //part3 of home screen
      'home_part3': '加入我們來了解更多',

      'event_01': '義務眼科檢查計劃',
      'event_01_description': '聽取專業人士的眼科護理建議',
      'event_02': '每周眼部護理文章',
      'event_02_description': ' 有趣的文章，事實和眼保健相關主題的調查！',
      'learn_more': '了解更多',
      'WATCH_VIDEOS': '觀看視頻',

      //part4 of home screen
      'home_part4': '我們的團隊',
      '0401': '總體而言，ForeSee 由五個團隊組成，分別是App開發、商務拓展、財務、人力資源和營運團隊。在上面，我們有Kiara 擔任首席執行官，Coco 擔任首席營運官，Jaman 擔任顧問以監督公司日常營運以及作出決策。',
      '0402': '在團隊內部方面，由 Alpha 帶領的App開發團隊中，成員包括 Jimmy、Jovan、Kamakshi、Monica、Oscar 和 Wilson。在商務拓展團隊裏面，Kendra 是組長， Ahmed、Marvin 和 Julie 負責推廣； Abbie 和 Emily 負責公共關係； Cathleen 和 Sankalp 負責網站開發。在財務團隊裏，Facundo 是首席財務官，成員包括 Amodth、Aryan、Dicky、Qiushi 和 Steve。在人力資源部，由 Chloris 負責與成員 Kathy 進行相關工作。至於營運團隊，Rita 是團隊負責人，成員包括 David、Joyce、Karen、Rakshita 和 Stephenie 。',
      
      
      //about: our story
      'our-story-title': '我們的故事',
      '0501': '注意到香港的眼科行業缺乏高效的眼科數據存檔和收集中心，並且香港兒童的近視率日漸增長，為了有效防止眼部健康退化並養成日常眼保健習慣，我們研發了一款一體式眼保健應用程序—ForeSee。',
      '0502': '自從2020年啟動該項目起，我們已經在Google Play Store成功推出了該綜合護眼移動應用2.0版本，目前正在開發iOS版本。',
      '0503': '除應用程序開發外，我們還致力於其他與眼部健康相關的活動。我們正在開展兒童眼科檢查項目，來在家長中推廣兒童定期檢查眼科的理念，以及早發現眼部問題並及時治療。此外，我們邀請眼科專業人士組織了在線研討會，並發起了一系列社交媒體活動，以傳播有關眼部保護的信息。',
      '0504': "促進改善眼睛健康，我們仍在路上……",



      //about: our members
      'our-members-title': '我們的成員', 
      //Different member names, positions and their respective description 
      '0601_name': 'Abbie',
      '0601_position': '商務拓展團隊（公關）',
      '0601_description': '我喜歡畫畫和彈鋼琴。我熱愛認識新朋友！',

      '0602_name': 'Ahmed',
      '0602_position': '商務拓展團隊（推廣）',
      '0602_description': '我喜歡爬山、踢足球、看足球賽、以及探索新的地方。我也喜歡做飯！',
      
      '0603_name': 'Alpha',
      '0603_position': 'App開發團隊（負責人）',
      '0603_description': '我是Alpha，在帶領程式開發團隊的這段時間裡，我為ForeSee感到十分驕傲，它從一個小型學生項目拓展到一個旨在為社會服務的熱心機構。',
      
      '0604_name': 'Aryan',
      '0604_position': '財務團隊',
      '0604_description': '在空閒時間，我喜歡看電影和電視節目（我是個電影迷），我也喜歡打籃球、乒乓球和羽毛球。',
      
      '0605_name': 'Cathleen',
      '0605_position': '商務拓展團隊（網頁開發）',
      '0605_description': '嗨！！！這是Cathleen。關於我的一件趣聞：你可以從照片裡看出來，我喜歡戴鴨舌帽。',
      
      '0606_name': 'Chloris',
      '0606_position': '人力資源團隊（負責人）',
      '0606_description': '這是Chloris！關於我的一件趣聞：我的笑點非常低，所以我往往笑很多。 ForeSee 的氛圍很棒，希望您能通過參加我們的活動來感受一下！',
      
      '0607_name': 'Coco',
      '0607_position': '營運長',
      '0607_description': "非常高興能成為ForeSee的一員，這對我來說絕對是一段獲益良多的旅程！如果您打開我們的應用程式並體驗護眼操功能，您會聽到我的聲音！",
      
      '0608_name': 'David',
      '0608_position': '營運團隊',
      '0608_description': '我的名字是David，一個三年級的本地本科生，主修決策分析學。一件關於我的趣聞：我的腦袋是由足球、日語和電影組成的 :)',
      
      '0609_name': 'Dicky',
      '0609_position': '財務團隊',
      '0609_description': '我是一個富有熱情的人，樂於在商業世界中探索更多。',
      
      '0610_name': 'Facundo',
      '0610_position': '財務長',
      '0610_description': '我的名字是Facundo Llamas，我來自阿根廷，在這學期即將畢業。一件趣聞：我在 8 個國家生活過。',
      
      '0611_name': 'Jaman',
      '0611_position': '顧問 & 商務拓展團隊（網頁開發）',
      '0611_description': '哈囉~ 我是ForeSee的Jaman！ 我喜歡說話和結交來自不同背景的朋友！😏 關於我的趣聞：我每個月只使用一次社交媒體😂',
      
      '0612_name': 'Jimmy',
      '0612_position': 'App開發團隊',
      '0612_description': '一個喜歡麻將和爬山的男孩',

      '0613_name': 'Jovan',
      '0613_position': 'App開發團隊',
      '0613_description': '我來自印度尼西亞，我喜歡食物、睡覺、騎自行車和電影。',

      '0614_name': 'Joyce',
      '0614_position': '營運團隊',
      '0614_description': '嗨我是 Joyce，是ForeSee營運團隊的資深成員，也是一名對長跑和文學有著極大的熱忱的商科學生。渴望與ForeSee團隊一起有所作為！',

      '0615_name': 'Julie',
      '0615_position': '商務拓展團隊（推廣）',
      '0615_description': '我喜歡看韓劇和打麻將。',

      '0616_name': 'Kamakshi',
      '0616_position': 'App開發團隊 (UI/UX)',
      '0616_description': '我是一個有條理、有創意及注重細節的人。 我有增值自己的心態，並通過創業計劃、MUN 會議及社交媒體課程提升個人發展。 我對攝影充滿熱情，亦喜歡看電影，哈利波特是我的最愛之一。',

      '0617_name': 'Kathy',
      '0617_position': '人力資源團隊',
      '0617_description': '我是一個外向的女孩，喜歡在商業領域工作。 此外，我希望從不同的工作經驗中學會更多。',

      '0618_name': 'Kendra',
      '0618_position': '商務拓展團隊（負責人）',
      '0618_description': '我是Kendra，負責 ForeSee 的業務發展。 在我看來，ForeSee 的成員是我見過最善良、最有動力的人。 能成為這個團隊的一員，我感到非常自豪和高興。',

      '0619_name': 'Marvin',
      '0619_position': '商務拓展團隊（推廣）',
      '0619_description': '你好呀！ 我的愛好包括下棋、玩遊戲和彈鋼琴。 我也喜歡遠足，但有一次我被困在遠足小徑的中間，在午夜才找到辦法下山。(那時候差點兒沒有公共汽車或麵包車可以返回市區) !',

      '0620_name': 'Oscar',
      '0620_position': 'App開發團隊',
      '0620_description': '我喜歡學習新事物及與人相處',

      '0621_name': 'Qiushi',
      '0621_position': '財務團隊',
      '0621_description': '我的名字是Qiushi，我是 WBB 四年級的學生。 我與 ForeSee 的財務團隊一起工作。 閒暇時，我喜歡跳現代舞。此外，我有一個雙胞胎兄弟。',

      '0622_name': 'Rakshita',
      '0622_position': '營運團隊',
      '0622_description': '我喜歡閱讀神秘/奇幻小說，偶爾會在Netflix追劇',

      '0623_name': 'Rita',
      '0623_position': '營運團隊 （負責人）',
      '0623_description': '你好！ 我是營運團隊的Rita~ 我現在是經濟和金融專業的三年級學生。 我大部分閒暇時間都花在戲劇和電視節目上，不過現在我正在努力做更多運動！ 很高興成為 ForeSee 大家庭的一員，希望您能享受與 ForeSee 的旅程！',

      '0624_name': 'Sankalp Verma',
      '0624_position': '商務拓展團隊（網頁開發）',
      '0624_description': '我的愛好包括看 Netflix、吃東西、思考接下來要吃什麼和拖延。 我的興趣包括樹木。',

      '0625_name': 'Shafira Monica',
      '0625_position': 'App開發團隊',
      '0625_description': "大家好，我是應用開發團隊的 Monica。 我喜歡旅行、看電影和烹飪（麵包愛好者）！ 期待為 ForeSee 帶來一些改變~",

      '0626_name': 'Stephanie',
      '0626_position': '營運團隊',
      '0626_description': '我喜歡懸疑類型的電視節目，我亦會通過購物雜貨來緩解壓力~',

      '0627_name': 'Steve',
      '0627_position': '財務團隊',
      '0627_description': '我真的很喜歡旅行，而且有英國北部的口音。',

      '0628_name': 'Wilson',
      '0628_position': 'App開發團隊',
      '0628_description': "哈囉！除了作為一名程序員之外，我還是音樂、體育和旅行的忠實粉絲。 如果您對這些東西有任何興趣，請隨時dm我 ;)。關於我的趣聞：我有絕對音感，但沒有達到 Charlie Puth的水平。xD",

      '0629_name': 'Amodh',
      '0629_position': '財務團隊',
      '0629_description': "嗨，我來自印度，對踢足球充滿熱忱。關於我的一件趣聞：我可以劈叉。",

      '0630_name': 'Karen',
      '0630_position': '營運團隊',
      '0630_description': '我很喜歡打麻將、旅行和體驗不同的文化。',
      
      '0631_name': 'Kiara',
      '0631_position': '執行長',
      '0631_description': '非常榮幸成為 ForeSee 的一員，希望您也喜歡 ForeSee！ 關於我的一件事：我一直熱衷於保持一個健康的睡眠時間表，但經常持續不下去 :)',
      
      // Appending for addition
      // '0632_name': '',
      // '0632_position': '',
      // '0632_description': '',


      //about: our mission
      'our-mission-title': '我們的使命',
      '0701': '我們致力於為當地社區提供必要的資源，以在早期傳播眼保健知識並防止眼健康惡化。',
      
      //about: our partners
      'our-partners-title': '我們的合作夥伴',
      '0801': '',
    

      //app: demo screen
      'app-showcase-title1': '視力圖譜',  
      '0901': '兒童的視力數據可以由家長或專業的視光師填寫。家長可用視力趨勢圖表功能觀察兒童的視力發展，並及早發現任何眼部問題。',   

      'app-showcase-title2': '護眼操', 
      '0902': '眼部運動有助兒童養成緩解眼睛疲勞的日常習慣。兒童可以根據錄音指示完成眼部運動，放鬆經常使用電子產品的雙眼。',

      'app-showcase-title3': 'Q&A問答區', 
      '0903': '家長可以在問答論壇上提出與眼睛有關的問題，而這些問題將由專業人士一一解答。',

      'app-showcase-title4': '護眼貼士', 
      '0904': 'ForeSee成員會定期分享原創的護眼貼士。眼部護理內容包括眼部健康見解、有益眼睛的食譜，以及眼科專業人士解答眼部護理常見問題的採訪影片。',

      //events: i care eye care *currently the chinese translation is missing*
      'title1': 'I Care Eye Care',
      '1001': '為了提高人們對眼保健的認識，我們在世界視力日邀請到香港大學的梁教授和林教授舉辦此講座。梁教授為大家介紹了損害視力的壞習慣，而林教授讓參與者更加了解近視。我們將會邀請更多眼科專家為大家分享眼科小知識，請關注我們獲取更多資訊！',

      //events: public eye check up
      'title2': '義務眼科檢查計劃',
      '1005': "良好的視力發展對孩子至關重要。 Foresee相信不論經濟狀況如何，每個孩子都應該有權接受適當的眼睛保健服務。因此，Foresee發起了一項活動，為3至12歲的兒童提供免費的視力檢查服務，並優先考慮弱勢家庭。",
      '1006' : "Foresee將與香港合資格驗光師合作，在每次免費視力檢查後為兒童提供專業意見。 這項活動可以幫助兒童儘早發現任何眼部疾病，並可以向驗光師尋求專業意見，並採取後續行動控制情況。",
      '1007' : "Foresee旨在向家長推廣孩子定期檢查眼睛的理念，同時通過早期發現眼部疾病來保障孩子的眼睛健康。",

      // events: eye health under covid 19
      'title3' : 'COVID-19: 疫情下的眼睛健康',
      'subtitle1' : 'COVID-19: 疫情下的眼睛健康',
      'subtitle2' : '# 十日跨平台計畫',
      '1008' : "漫長的 COVID-19 疫情不僅為我們的日常生活帶來巨大的變化，隨著人們在電子產品上花費的時間越來越多，疫情亦嚴重影響了我們的眼睛健康.",
      '1009' : "有鑑於此，ForeSee 想在 Instagram、Facebook 和 YouTube 上舉辦為期十天的跨平台活動，旨在闡述疫情下所衍生的眼健康問題及提供相關建議。",
      '1010' : '活動內容將涵蓋現象科普、眼科​​教授採訪、護眼貼士，以及有獎競答等。敬請關注我們的社交媒體以瞭解更多信息!',
      'link' : '',
      // '1011' : '',
      // 'link2' : '',


      //contact us
      'contact_us': '聯繫我們',
      '1101': '給我們留言',

      // Navibar
      // some of the below items are reused items later   
      'home': '主頁',
      'about': '關於',
      'App': '手機應用程式',
      // 'our-story-title': '我們的故事',
      // 'home_part4': '我們的團隊',
      // 'our-mission-title': '我們的使命',
      // 'our-partners-title': '我们的合作伙伴',
      // 'contact_us': 'Contact Us',
      'event': '企劃',
      // 'title1': 'I Care Eye Care',
      'language': '語言',




    }
  }, 
  
//Below is simplified Chinese translations
  'zh-CN': {
    translation: {
      'Welcome': '大家好！',
      //Home Screen
      'Home__title': '我们是ForeSee 团队',
      'Home__content1': '一个科大学生主导的机构',
      'Home__content2': '致力于传播眼睛健康和视力保护的意识',
      //'Home__follow': '>了解更多 > (我们的宗旨)  加入我们 > (加入我们)',

      //slider: home screen
      'slider2__title01': 'ForeSee - 一个科技大学学生组织',
      '0101': 'ForeSee 是一个以学生发起的组织，专注于香港大众的眼部健康。',
      '0102': '其使命是提高公众对视力保健的认识，尤其针对幼儿及其父母。',
      '0103': 'ForeSee 由一群来自香港科技大学（HKUST）的热心学生组成，他们关心公众的眼睛健康，并热衷于贡献和服务香港社会。 它目前在香港科技大学SIGHT（视界无限）项目的支持下运作，并作为一个香港科技大学的学生组织积极发起和参与多个与眼部健康相关的项目和活动。',

      'slider2__title02': 'ForeSee - 一个眼部健康手机应用程序',
      '0201': 'ForeSee 应用程序是ForeSee 团队推出的一个项目。 它是一款以眼部健康为主题的手机应用程序，旨在为日常眼部保健提供一站式解决方案。 现阶段，该应用主要针对香港地区3-12岁的儿童及其父母。 其功能亮点包括动态视力趋势图、关于眼睛健康的文章和视频、用于自测的视力图表。',
      '0202': '该应用程序现已在 Google Play 商店上架。 iOS版本还在开发中，敬请期待。',
      
      
      //part3 of home screen
      'home_part3': '加入我们来了解更多',

      'event_01': '义务眼科检查计划',
      'event_01_description': '听取专业人士的眼科护理建议。 ',
      'event_02': '每周眼部护理文章',
      'event_02_description': ' 有趣的文章，事实和眼保健相关主题的调查！',
      'learn_more': '阅读更多',
      'WATCH_VIDEOS': '观看视频',

      //part4 of home screen
      'home_part4': '我们的团队',
      '0401': '总体而言，ForeSee由五个团队组成，分别是App开发、商务拓展、财务、人力资源和运营团队。 在上面，我们有Kiara担任首席执行官，Coco担任首席运营官，Jaman 担任顾问以监督公司日常运营以及作出决策。',
      '0402': '在团队内部方面，由 Alpha 带领的App开发团队中，成员包括 Jimmy、Jovan、Kamakshi、Monica、Oscar 和 Wilson。 在商务拓展团队里面，Kendra 是组长， Ahmed、Marvin 和 Julie 负责推广; Abbie 和 Emily 负责公共关系; Cathleen 和 Sankalp 负责网站开发。 在财务团队里，Facundo 是首席财务官，成员包括 Amodth、Aryan、Dicky、Qiushi 和 Steve。 在人力资源部，由 Chloris 负责与成员 Kathy 进行相关工作。 至于运营团队，Rita 是团队负责人，成员包括 David、Joyce、Karen、Rakshita 和 Stephenie 。',
      // reuse componet here 'read_more': '阅读更多',
      
      //about: our story
      'our-story-title': '我们的故事',
      '0501': '注意到香港的眼科行业缺乏高效的眼科数据存档和收集中心，并且香港儿童的近视率日渐增长，为了有效防止眼部健康退化并养成日常眼保健习惯，我们研发了一款一体式眼保健应用程序—ForeSee。',
      '0502': '自从2020年启动该项目起，我们已经在Google Play Store成功推出了该综合护眼移动应用2.0版本，目前正在开发iOS版本。',
      '0503': '除应用程序开发外，我们还致力于其他与眼部健康相关的活动。 我们正在开展儿童眼科检查项目，来在家长中推广儿童定期检查眼科的理念，以及早发现眼部问题并及时治疗。 此外，我们邀请眼科专业人士组织了在线研讨会，并发起了一系列社交媒体活动，以传播有关眼部保护的信息。',
      '0504': "促进改善眼睛健康，我们仍在路上......",



      //about: our members
      'our-members-title': '我们的成员', 
      //Different member names, positions and their respective description 
      '0601_name': 'Abbie',
      '0601_position': '商务拓展团队（公关）',
      '0601_description': '我喜欢画画和弹钢琴。 我热爱认识新朋友！',

      '0602_name': 'Ahmed',
      '0602_position': '商务拓展团队（推广）',
      '0602_description': '我喜欢爬山、踢足球、看足球赛、以及探索新的地方。 我也喜欢做饭！',
      
      '0603_name': 'Alpha',
      '0603_position': 'App开发团队（负责人）',
      '0603_description': '我是Alpha，在带领程序开发团队的这段时间里，我为ForeSee感到十分骄傲，它从一个小型学生项目拓展到一个旨在为社会服务的热心机构。',
      
      '0604_name': 'Aryan',
      '0604_position': '财务团队',
      '0604_description': '在空闲时间，我喜欢看电影和电视节目（我是个电影迷），我也喜欢打篮球、乒乓球和羽毛球。',
      
      '0605_name': 'Cathleen',
      '0605_position': '商务拓展团队（网页开发）',
      '0605_description': '嗨!!! 这是Cathleen。 关于我的一件趣闻：你可以从照片里看出来，我喜欢戴鸭舌帽。',
      
      '0606_name': 'Chloris',
      '0606_position': '人力资源团队（负责人）',
      '0606_description': '这是Chloris！ 关于我的一件趣闻：我的笑点非常低，所以我往往笑很多。 ForeSee 的氛围很棒，希望您能通过参加我们的活动来感受一下！',
      
      '0607_name': 'Coco',
      '0607_position': '营运长',
      '0607_description': "非常高兴能成为ForeSee的一员，这对我来说绝对是一段获益良多的旅程！ 如果您打开我们的应用程序并体验护眼操功能，您会听到我的声音！",
      
      '0608_name': 'David',
      '0608_position': '营运团队',
      '0608_description': '我的名字是David，一个三年级的本地本科生，主修决策分析学。 一件关于我的趣闻：我的脑袋是由足球、日语和电影组成的 :)',
      
      '0609_name': 'Dicky',
      '0609_position': '财务团队',
      '0609_description': '我是一个富有热情的人，乐于在商业世界中探索更多。',
      
      '0610_name': 'Facundo',
      '0610_position': '财务主管',
      '0610_description': '我的名字是Facundo Llamas，我来自阿根廷，在这学期即将毕业。 一件趣闻：我在 8 个国家生活过。',
      
      '0611_name': 'Jaman',
      '0611_position': '顧問 & 商務拓展團隊（網頁開發）',
      '0611_description': '哈啰~ 我是ForeSee的Jaman！ 我喜欢说话和结交来自不同背景的朋友！ 😏 关于我的趣闻：我每个月只使用一次社交媒体 😂',
      
      '0612_name': 'Jimmy',
      '0612_position': 'App开发团队',
      '0612_description': '一个喜欢麻将和爬山的男孩',

      '0613_name': 'Jovan',
      '0613_position': 'App开发团队',
      '0613_description': '我来自印度尼西亚，我喜欢食物、睡觉、骑自行车和电影。',

      '0614_name': 'Joyce',
      '0614_position': '营运团队',
      '0614_description': '嗨我是 Joyce，是ForeSee营运团队的资深成员，也是一名对长跑和文学有着极大的热忱的商科学生。 渴望与ForeSee团队一起有所作为！',

      '0615_name': 'Julie',
      '0615_position': '商务拓展团队（推广）',
      '0615_description': '我喜欢看韩剧和打麻将。',

      '0616_name': 'Kamakshi',
      '0616_position': 'App开发团队 （UI/UX）',
      '0616_description': '我是一个有条理、有创意及注重细节的人。 我有增值自己的心态，并通过创业计划、MUN 会议及社交媒体课程提升个人发展。 我对摄影充满热情，亦喜欢看电影，哈利波特是我的最爱之一。',

      '0617_name': 'Kathy',
      '0617_position': '人力资源团队',
      '0617_description': '我是一个外向的女孩，喜欢在商业领域工作。 此外，我希望从不同的工作经验中学会更多。',

      '0618_name': 'Kendra',
      '0618_position': '商务拓展团队（负责人）',
      '0618_description': '我是Kendra，负责 ForeSee 的业务发展。 在我看来，ForeSee的成员是我见过最善良、最有动力的人。 能成为这个团队的一员，我感到非常自豪和高兴。',

      '0619_name': 'Marvin',
      '0619_position': '商务拓展团队（推广）',
      '0619_description': '你好呀！ 我的爱好包括下棋、玩游戏和弹钢琴。 我也喜欢远足，但有一次我被困在远足小径的中间，在午夜才找到办法下山。 （那时候差点儿没有公共汽车或面包车可以返回市区） ！',

      '0620_name': 'Oscar',
      '0620_position': 'App开发团队',
      '0620_description': '我喜欢学习新事物及与人相处',

      '0621_name': 'Qiushi',
      '0621_position': '财务团队',
      '0621_description': '我的名字是Qiushi，我是 WBB 四年级的学生。 我与 ForeSee 的财务团队一起工作。 闲暇时，我喜欢跳现代舞。 此外，我有一个双胞胎兄弟。',

      '0622_name': 'Rakshita',
      '0622_position': '营运团队',
      '0622_description': '我喜欢阅读神秘/奇幻小说，偶尔会在Netflix追剧',

      '0623_name': 'Rita',
      '0623_position': '运营团队（ 负责人）',
      '0623_description': '你好！ 我是营运团队的Rita~ 我现在是经济和金融专业的三年级学生。 我大部分闲暇时间都花在戏剧和电视节目上，不过现在我正在努力做更多运动！ 很高兴成为 ForeSee 大家庭的一员，希望您能享受与 ForeSee 的旅程！',

      '0624_name': 'Sankalp Verma',
      '0624_position': '商务拓展团队（网页开发）',
      '0624_description': '我的爱好包括看 Netflix、吃东西、思考接下来要吃什么和拖延。 我的兴趣包括树木。',

      '0625_name': 'Shafira Monica',
      '0625_position': 'App开发团队',
      '0625_description': "大家好，我是应用开发团队的 Monica。 我喜欢旅行、看电影和烹饪（面包爱好者）！ 期待为 ForeSee 带来一些改变~",

      '0626_name': 'Stephanie',
      '0626_position': '营运团队',
      '0626_description': '我喜欢悬疑类型的电视节目，我亦会通过购物杂货来缓解压力~',

      '0627_name': 'Steve',
      '0627_position': '财务团队',
      '0627_description': '我真的很喜欢旅行，而且有英国北部的口音。',

      '0628_name': 'Wilson',
      '0628_position': 'App开发团队',
      '0628_description': "哈啰！ 除了作为一名程序员之外，我还是音乐、体育和旅行的忠实粉丝。 如果您对这些东西有任何兴趣，请随时dm我 ;)。 关于我的趣闻：我有绝对音感，但没有达到 Charlie Puth 的水平。 xD",

      '0629_name': 'Amodh',
      '0629_position': '财务团队',
      '0629_description': "嗨，我来自印度，对踢足球充满热忱。 关于我的一件趣闻：我可以劈叉。",

      '0630_name': 'Karen',
      '0630_position': '营运团队',
      '0630_description': '我很喜欢打麻将、旅行和体验不同的文化。',
      
      '0631_name': 'Kiara',
      '0631_position': '执行长',
      '0631_description': '非常荣幸成为 ForeSee 的一员，希望您也喜欢 ForeSee！ 关于我的一件事：我一直热衷于保持一个健康的睡眠时间表，但经常持续不下去 :)',
      
      // Appending for addition
      // '0632_name': '',
      // '0632_position': '',
      // '0632_description': '',


      //about: our mission
      'our-mission-title': '我们的使命',
      '0701': '我们致力于为当地社区提供必要的资源，以在早期传播眼保健知识并防止眼健康恶化。',
      
      //about: our partners
      'our-partners-title': '我们的合作伙伴',
      '0801': '',
    

      //app: demo screen
      'app-showcase-title1': '视力图谱',  
      '0901': '视力图谱儿童的视力数据可以由家长或专业的视光师填写。 家长可用视力趋势图表功能观察儿童的视力发展，并及早发现任何眼部问题。',   

      'app-showcase-title2': '护眼操', 
      '0902': '眼部运动有助儿童养成缓解眼睛疲劳的日常习惯。 儿童可以根据录音指示完成眼部运动，放松经常使用电子产品的双眼。',

      'app-showcase-title3': 'Q&A问答区', 
      '0903': '家长可以在问答论坛上提出与眼睛有关的问题，而这些问题将由专业人士一一解答。',

      'app-showcase-title4': '护眼贴士', 
      '0904': 'ForeSee成员会定期分享原创的护眼贴士。 眼部护理内容包括眼部健康见解、有益眼睛的食谱，以及眼科专业人士解答眼部护理常见问题的采访影片。',

      //events: i care eye care *currently the chinese translation is missing*
      'title1': 'I Care Eye Care',
      '1001': 'This event was conducted on World Sight Day to raise awareness about eye care, wherein HKU Professors Leung and Lam were guest speakers. Professor Leung discussed habits that harm vision, while Professor Lam talked about myopia.',
      '1002': 'Professor Leung tested the audience’s knowledge about eye care-related habits, such as whether they knew that reading while lying down is harmless or that blueberries do not improve eye health. He also falsified the myth that eye exercise can improve vision. Moreover, he talked about how smoking can cause blinding eye diseases, but wearing blue-light blocking glasses won’t prevent them.',
      '1003': 'Professor Lam first defined myopia, which causes distant objects to appear blurred. He then showed a chart displaying myopia’s prevalence. The highest prevalence was in East Asia, solidifying East Asian ethnicity as a risk factor. Moreover, one or both parents having myopia also puts the child at risk.',
      '1004': 'The risk of myopia-related conditions is proportional to an increase in the degree of myopia. Professor Lam also busted some myopia myths, such as pressing eyes to shorten eyeballs. He compared long-term (LASIK) and short-term treatments (orthokeratology) of myopia. Lastly, he discussed the importance of the 20/20/20 rule and spending time outdoors to prevent eye strain.',


      //events: public eye check up
      'title2': '义务眼科检查计划',
      '1005': "良好的视力发展对孩子至关重要。 Foresee相信不论经济状况如何，每个孩子都应该有权接受适当的眼睛保健服务。 因此，Foresee发起了一项活动，为3至12岁的儿童提供免费的视力检查服务，并优先考虑弱势家庭。 Foresee将与香港合资格验光师合作，在每次免费视力检查后为儿童提供专业意见。 这项活动可以帮助儿童尽早发现任何眼部疾病，并可以向验光师寻求专业意见，并采取后续行动控制情况。 Foresee旨在向家长推广孩子定期检查眼睛的理念，同时通过早期发现眼部疾病来保障孩子的眼睛健康。",
      '1006' : "Foresee將與香港合資格驗光師合作，在每次免費視力檢查後為兒童提供專業意見。 這項活動可以幫助兒童儘早發現任何眼部Foresee将与香港合资格验光师合作，在每次免费视力检查后为儿童提供专业意见。这项活动可以帮助儿童尽早发现任何眼部疾病，并可以向验光师寻求专业意见，并采取后续行动控制情况。疾病，並可以向驗光師尋求專業意見，並採取後續行動控制情況。",
      '1007' : "Foresee旨在向家長推廣孩子定期檢查眼睛Foresee旨在向家长推广孩子定期检查眼睛的理念，同时通过早期发现眼部疾病来保障孩子的眼睛健康。的理念，同時通過早期發現眼部疾病來保障孩子的眼睛健康。",

      // events: eye health under covid 19
      'title3' : 'COVID-19: 疫情下的眼睛健康',
      'subtitle1' : 'COVID-19: 疫情下的眼睛健康',
      'subtitle2' : '# 十日跨平台计划',
      '1008' : "漫长的COVID-19疫情不仅为我们的日常生活带来巨大的变化，随着人们在电子产品上花费的时间越来越多，疫情也严重影响了我们的眼睛健康。",
      '1009' : "有鉴于此，ForeSee想在Instagram、Facebook和YouTube上举办为期十天的跨平台活动，旨在阐述疫情下所衍生的眼睛健康问题及提供相关建议。",
      '1010' : '活动内容将涵盖现象科普、眼科教授采访、护眼窍门，以及幸运抽奖等。敬请关注我们的社交媒体以了解更多信息！',
      'link' : '',
      // '1011' : '',
      // 'link2' : '',



      //contact us
      'contact_us': '联系我们',
      '1101': '给我们留言',



      // Navibar
      // some of the below items are reused items later   
      'home': '主页',
      'App': '手机应用程序',
      'about': '关于',
      // 'our-story-title': '我們的故事',
      // 'home_part4': '我們的團隊',
      // 'our-mission-title': '我們的使命',
      // 'our-partners-title': '我们的合作伙伴',
      // 'contact_us': 'Contact Us',
      'event': '企划',
      // 'title1': 'I Care Eye Care',
      'language': '语言',









    }
  }
};

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next).init({
    detection: { order: ["path", "navigator"] },
    resources,
//   lng: 'en', it is undefined here cuz we are using a language detector
    fallbackLng: "en",
    supportedLngs: ['en', 'zh-TW', 'zh-CN'],

    interpolation: {
        escapeValue: false
    }
});

export default i18n;