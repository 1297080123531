import React from 'react';
import poster from '../Assets/Picture2.png';
import { useTranslation } from 'react-i18next'
import './EyeHealthUnderCovid19.css';

const EyeHealthUnderCovid19 = () => {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className='title'>
                <h1>{t('title3')}</h1>
            </div>
            <div className='content'>
                <img src={poster} alt={poster} />
                <p>
                    {/* <button onClick={() => changeLanguageHandler('en')} />
                    <button onClick={() => changeLanguageHandler('zh')} />
                    <br /> */}
                    {/* {t('Welcome')} */}
                    <h4>{t('subtitle1')}</h4>
                    <h4>{t('subtitle2')}</h4>
                    <br />
                    {t('1008')} 
                    <br/> <br/>
                    {t('1009')} 
                    <br/> <br/>
                    {t('1010')} 
                    <br/> <br/>
                    <a href='https://linktr.ee/foresee_hk'>{t('link')}</a>
                </p>
            </div>
            {/* video for youtube embed */}
            {/* <div className='video'>
                <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/J7hWTKmZ6G8" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                <p>
                    <h4>{t('subtitle1')}</h4>
                    <h4>{t('subtitle2')}</h4>
                    <br />
                    <h3>{t('subtitle3')}</h3>
                    {t('1011')} 
                    <br/> <br/>
                    <a href='#'>{t('link2')}</a>
                </p>
            </div> */}
        </div>
    )
}

export default EyeHealthUnderCovid19;