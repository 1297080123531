import React from 'react';
import { useTranslation } from 'react-i18next';
import forsee_img from '../../Assets/ForeSee_Banner.png';
import './OurStory.css';

const OurStory = () => {
    const { t, i18n } = useTranslation();
    // const changeLanguageHandler = (lang) => i18n.changeLanguage(lang);

    return (
        <div>
            <div className='our-story-title'>
                <h1>{t('our-story-title')}</h1>
            </div>
            <div className='our-story-content'>
                <img src={forsee_img} alt="test" />
                <p>
                    {/* <button onClick={() => changeLanguageHandler('en')} />
                    <button onClick={() => changeLanguageHandler('zh')} />
                    <br /> */}
                    {/* {t('Welcome')} */}
                    <br />
                    {t('0501')}
                    <br/> <br/>
                    {t('0502')}
                    <br /> <br/>
                    {t('0503')}
                    <br/> <br/>
                    {t('0504')}
                </p>
            </div>
        </div>
    )
}

export default OurStory;