import React from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';
import green_background from "../Assets/green_background.jpeg";
import facebookqr from "../Assets/Facebook.png";
import instagramqr from "../Assets/Instagram.png";
import linkedinqr from "../Assets/LinkedIn.png";
import playstoreqr from "../Assets/GooglePlay.png";
import linktreeqr from "../Assets/Linktree.png";
import youtubeqr from "../Assets/YouTube.png";
import mailicon from "../Assets/iconmail.png";

const Contact = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className= "contact">
            <h1 className="title">{t('contact_us')}</h1>
            <img src={facebookqr} alt="logo" className="facebookqr" />
            <img src={instagramqr} alt="logo" className="instagramqr"/>
            <img src={linkedinqr} alt="logo" className="linkedinqr"/>
            <img src={playstoreqr} alt="logo" className="playstoreqr"/>
            <img src={youtubeqr} alt="logo" className="youtubeqr"/>
            <img src={linktreeqr} alt="logo" className="linktreeqr"/>
            <h1 className="subtitle">{t('1101')}</h1>
            <div className="mail">
                <img src={mailicon} alt="logo" className="mailicon" />
                <ul className="email">sightforesee@ust.hk</ul>
            </div>
        </div>
    )
}

export default Contact;