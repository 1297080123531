import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../Assets/AppScreenImages/app_img1.png";
import img2 from "../../Assets/AppScreenImages/app_img2.png";
import img3 from "../../Assets/AppScreenImages/app_img3.png";
import img4 from "../../Assets/AppScreenImages/app_img4.png";
import img5 from "../../Assets/AppScreenImages/app_img5.png";
import img6 from "../../Assets/AppScreenImages/app_img6.png";
import img7 from "../../Assets/AppScreenImages/app_img7.png";
import img8 from "../../Assets/AppScreenImages/app_img8.png";
import img9 from "../../Assets/AppScreenImages/app_img9.png";
import img10 from "../../Assets/AppScreenImages/app_img10.png";
import img11 from "../../Assets/AppScreenImages/app_img11.png";
import "./AppScreen.css";

const appImg = (pos) => {
  return {
    top: pos * 30 + "px",
    left: 80 + pos * -50 + "px",
  };
};

const AppScreen = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="app-screens-container">
      <div className="app-screens">
        <h4 className="app-showcase-title">{t("app-showcase-title1")}</h4>
        <img src={img2} className="app-img" style={appImg(0)} />
        <img src={img1} className="app-img" style={appImg(1)} />
        <p className="app-screens-text">{t("0901")}</p>
      </div>
      <div className="app-screens">
        <h4 className="app-showcase-title">{t("app-showcase-title2")}</h4>
        <img src={img3} className="app-img" style={appImg(0)} />
        <img src={img4} className="app-img" style={appImg(1)} />
        <img src={img5} className="app-img" style={appImg(2)} />
        <p className="app-screens-text">{t("0902")}</p>
      </div>
      <div className="app-screens">
        <h4 className="app-showcase-title">{t("app-showcase-title3")}</h4>
        <img src={img6} className="app-img" style={appImg(0)} />
        <img src={img7} className="app-img" style={appImg(1)} />
        <img src={img8} className="app-img" style={appImg(2)} />
        <p className="app-screens-text">{t("0903")}</p>
      </div>
      <div className="app-screens">
        <h4 className="app-showcase-title">{t("app-showcase-title4")}</h4>
        <img src={img9} className="app-img" style={appImg(0)} />
        <img src={img10} className="app-img" style={appImg(1)} />
        <img src={img11} className="app-img" style={appImg(2)} />
        <p className="app-screens-text">{t("0904")}</p>
      </div>
    </div>
  );
};

export default AppScreen;
