import React from 'react';
import { withTranslation } from 'react-i18next';
import './OurMembers.css';
import teamMember from '../../Assets/foresee_team.png';
import member1 from '../../Assets/OurMembersImages/forsee_team1.png';
import member2 from '../../Assets/OurMembersImages/forsee_member2.png';
import member3 from '../../Assets/OurMembersImages/forsee_member3.png';
import member4 from '../../Assets/OurMembersImages/forsee_member4.png';
import member5 from '../../Assets/OurMembersImages/forsee_member5.png';
import member6 from '../../Assets/OurMembersImages/forsee_member6.png';
import member7 from '../../Assets/OurMembersImages/forsee_member7.png';
import member8 from '../../Assets/OurMembersImages/forsee_member8.png';
import member9 from '../../Assets/OurMembersImages/forsee_member9.png';
import member10 from '../../Assets/OurMembersImages/forsee_member10.png';
import member11 from '../../Assets/OurMembersImages/forsee_member11.png';
import member12 from '../../Assets/OurMembersImages/forsee_member12.png';
import member13 from '../../Assets/OurMembersImages/forsee_member13.png';
import member14 from '../../Assets/OurMembersImages/forsee_member14.png';
import member15 from '../../Assets/OurMembersImages/forsee_member15.png';
import member16 from '../../Assets/OurMembersImages/forsee_member16.png';
import member17 from '../../Assets/OurMembersImages/forsee_member17.png';
import member18 from '../../Assets/OurMembersImages/forsee_member18.png';
import member19 from '../../Assets/OurMembersImages/forsee_member19.png';
import member20 from '../../Assets/OurMembersImages/forsee_member20.png';
import member21 from '../../Assets/OurMembersImages/forsee_member21.png';
import member22 from '../../Assets/OurMembersImages/forsee_member22.png';
import member23 from '../../Assets/OurMembersImages/forsee_member23.png';
import member24 from '../../Assets/OurMembersImages/forsee_member24.png';
import member25 from '../../Assets/OurMembersImages/forsee_member25.png';
import member26 from '../../Assets/OurMembersImages/forsee_member26.png';
import member27 from '../../Assets/OurMembersImages/forsee_member27.png';
import member28 from '../../Assets/OurMembersImages/forsee_member28.png';
import member29 from '../../Assets/OurMembersImages/forsee_member29.png';
import member30 from '../../Assets/OurMembersImages/forsee_member30.png';
import member31 from '../../Assets/OurMembersImages/forsee_member31.png';
import rightArrow from '../../Assets/rightArrow.png';
import leftArrow from '../../Assets/prevArrow.png';
import Slider from 'react-slick';

class OurMembers extends React.Component {
    
    constructor() {
        
        super();
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
      }
    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 3, //3
            slidesToScroll: 3,
          };
        const { t } = this.props;
        // const { t, i18n } = useTranslation();

        return (
            <div className='our-members-main'>
                <div className='our-members-title'>
                    <h1>{t('our-members-title')}</h1>
                </div>
                <Slider ref={c => (this.slider = c)} {...settings} className='our-members-slider' >
                    <div>
                        <img src={member1} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0601_name')}</h3>
                        <h5>{t('0601_position')}</h5>
                        <p>{t('0601_description')}</p>
                    </div>
                    <div>
                        <img src={member2} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0602_name')}</h3>
                        <h5>{t('0602_position')}</h5>
                        <p>{t('0602_description')}</p>
                    </div>
                    <div>
                        <img src={member3} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0603_name')}</h3>
                        <h5>{t('0603_position')}</h5>
                        <p>{t('0603_description')}</p>
                    </div>
                    <div>
                        <img src={member4} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0629_name')}</h3>
                        <h5>{t('0629_position')}</h5>
                        <p>{t('0629_description')}</p>
                    </div>
                    <div>
                        <img src={member5} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0604_name')}</h3>
                        <h5>{t('0604_position')}</h5>
                        <p>{t('0604_description')}</p>
                    </div>
                    <div>
                        <img src={member6} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0605_name')}</h3>
                        <h5>{t('0605_position')}</h5>
                        <p>{t('0605_description')}</p>
                    </div>
                    <div>
                        <img src={member7} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0606_name')}</h3>
                        <h5>{t('0606_position')}</h5>
                        <p>{t('0606_description')}</p>
                    </div>
                    <div>
                        <img src={member8} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0607_name')}</h3>
                        <h5>{t('0607_position')}</h5>
                        <p>{t('0607_description')}</p>
                    </div>
                    <div>
                        <img src={member9} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0608_name')}</h3>
                        <h5>{t('0608_position')}</h5>
                        <p>{t('0608_description')}</p>
                    </div>
                    <div>
                        <img src={member10} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0609_name')}</h3>
                        <h5>{t('0609_position')}</h5>
                        <p>{t('0609_description')}</p>
                    </div>
                    <div>
                        <img src={member11} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0610_name')}</h3>
                        <h5>{t('0610_position')}</h5>
                        <p>{t('0610_description')}</p>
                    </div>
                    <div>
                        <img src={member12} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0611_name')}</h3>
                        <h5>{t('0611_position')}</h5>
                        <p>{t('0611_description')}</p>
                    </div>
                    <div>
                        <img src={member13} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0612_name')}</h3>
                        <h5>{t('0612_position')}</h5>
                        <p>{t('0612_description')}</p>
                    </div>
                    <div>
                        <img src={member14} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0613_name')}</h3>
                        <h5>{t('0613_position')}</h5>
                        <p>{t('0613_description')}</p>
                    </div>
                    <div>
                        <img src={member15} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0614_name')}</h3>
                        <h5>{t('0614_position')}</h5>
                        <p>{t('0614_description')}</p>
                    </div>
                    <div>
                        <img src={member16} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0615_name')}</h3>
                        <h5>{t('0615_position')}</h5>
                        <p>{t('0615_description')}</p>
                    </div>
                    <div>
                        <img src={member17} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0616_name')}</h3>
                        <h5>{t('0616_position')}</h5>
                        <p>{t('0616_description')}</p>
                    </div>
                    <div>
                        <img src={member18} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0630_name')}</h3>
                        <h5>{t('0630_position')}</h5>
                        <p>{t('0630_description')}</p>
                    </div>
                    <div>
                        <img src={member19} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0617_name')}</h3>
                        <h5>{t('0617_position')}</h5>
                        <p>{t('0617_description')}</p>
                    </div>
                    <div>
                        <img src={member20} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0618_name')}</h3>
                        <h5>{t('0618_position')}</h5>
                        <p>{t('0618_description')}</p>
                    </div>
                    <div>
                        <img src={member22} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0619_name')}</h3>
                        <h5>{t('0619_position')}</h5>
                        <p>{t('0619_description')}</p>
                    </div>
                    <div>
                        <img src={member21} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0631_name')}</h3>
                        <h5>{t('0631_position')}</h5>
                        <p>{t('0631_description')}</p>
                    </div>
                    <div>
                        <img src={member23} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0620_name')}</h3>
                        <h5>{t('0620_position')}</h5>
                        <p>{t('0620_description')}</p>
                    </div>
                    <div>
                        <img src={member24} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0621_name')}</h3>
                        <h5>{t('0621_position')}</h5>
                        <p>{t('0621_description')}</p>
                    </div>
                    <div>
                        <img src={member25} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0622_name')}</h3>
                        <h5>{t('0622_position')}</h5>
                        <p>{t('0622_description')}</p>
                    </div>
                    <div>
                        <img src={member26} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0623_name')}</h3>
                        <h5>{t('0623_position')}</h5>
                        <p>{t('0623_description')}</p>
                    </div>
                    <div>
                        <img src={member27} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0624_name')}</h3>
                        <h5>{t('0624_position')}</h5>
                        <p>{t('0624_description')}</p>
                    </div>
                    <div>
                        <img src={member28} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0625_name')}</h3>
                        <h5>{t('0625_position')}</h5>
                        <p>{t('0625_description')}</p>
                    </div>
                    <div>
                        <img src={member29} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0626_name')}</h3>
                        <h5>{t('0626_position')}</h5>
                        <p>{t('0626_description')}</p>
                    </div>
                    <div>
                        <img src={member30} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0627_name')}</h3>
                        <h5>{t('0627_position')}</h5>
                        <p>{t('0627_description')}</p>
                    </div>
                    <div>
                        <img src={member31} alt="forsee member" className='our-members-photo' />
                        <h3>{t('0628_name')}</h3>
                        <h5>{t('0628_position')}</h5>
                        <p>{t('0628_description')}</p>
                    </div>
                </Slider>
                <div style={{ textAlign: "center" }}>
                    <button className="our-members-prev-button" onClick={this.previous}>
                        <img src={leftArrow} style={{width: '40px', height: '40px'}} />
                    </button>
                    <button className="our-members-next-button" onClick={this.next}>
                        <img src={rightArrow} style={{width: '40px', height: '40px'}} />
                    </button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OurMembers);