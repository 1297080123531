import React from 'react';
import poster from '../Assets/poster.png';
import people2 from '../Assets/people2.png';
import people1 from '../Assets/people1.png';
import { useTranslation } from 'react-i18next'
import './ICareEyeCare.css';

const ICareEyeCare = () => {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className='title'>
                <h1>{t('title1')}</h1>
            </div>
            <div className='content'>
                <img src={poster} alt={poster} />
                <p>
                    {/* <button onClick={() => changeLanguageHandler('en')} />
                    <button onClick={() => changeLanguageHandler('zh')} />
                    <br /> */}
                    {/* {t('Welcome')} */}
                    <br />
                    {t('1001')} 
                    <br/> <br/>
                    <a href='https://www.instagram.com/p/CWNC3YDhRy7/'>{t('read_more')}</a>
                </p>
            </div>
            {/* <div className='images'>
                <img src={people1} alt={people1} className="people"/>
                <img src={people2} alt={people2} className="people"/>
            </div>  */}
        </div>
    )
}

export default ICareEyeCare;