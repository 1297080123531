import React from 'react';
import { useTranslation } from 'react-i18next'
import forsee_img from '../../Assets/foresee_mission.jpg';
import './OurMission.css';

const OurMission = () => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <div className='our-mission-title'>
                <h1>{t('our-mission-title')}</h1>
            </div>
            <div className='our-mission-content'>
                <img src={forsee_img} alt="test" />
                <p>
                {t('0701')} 
                </p>
            </div>
        </div>
    )
}

export default OurMission;