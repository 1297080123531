import React from 'react';
import { useTranslation } from 'react-i18next'
import sight from '../../Assets/sight.png';
import dicklo from '../../Assets/dicklo.png';
import './OurPartners.css';

const OurPartners = () => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <div className='our-partners-title'>
                <h1>{t('our-partners-title')}</h1>
            </div>
            <div className='our-partners-content'>
                <img src={dicklo} alt="our partner" className='img-1' />
                <img src={sight} alt="our partner" className='img-2' />
            </div>
            <div className='our-partners-content-text'>
                <h4 className='text-1'>Mr. Dick Lo</h4>
                <h4 className='text-2'>HKUST Student Innovation <br/> for Global Health Technology</h4>
            </div>
        </div>
    )
}

export default OurPartners;