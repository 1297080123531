import './App.css';
import AppNavbar from './Components/AppNavbar';
import Slider2 from "./Components/Slider2";

function App() {
  return (
    <div className='main'>
      <AppNavbar />

    </div>
  );
}

export default App;
