import React from 'react';
import poster from '../Assets/eyecheckupposter.png';
import { useTranslation } from 'react-i18next'
import './PublicEyeCheckUp.css';

const PublicEyeCheckUp = () => {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className='title'>
                <h1>{t('title2')}</h1>
            </div>
            <div className='content'>
                <img src={poster} alt={poster} />
                <p>
                    {/* <button onClick={() => changeLanguageHandler('en')} />
                    <button onClick={() => changeLanguageHandler('zh')} />
                    <br /> */}
                    {/* {t('Welcome')} */}
                    <br />
                    {t('1005')} 
                    <br/> <br/>
                    {t('1006')} 
                    <br/> <br/>
                    {t('1007')} 
                    <br/> <br/>
                </p>
            </div>
        </div>
    )
}

export default PublicEyeCheckUp;