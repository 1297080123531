import React, { useState } from 'react';
import './AppNavbar.css';
import logo from '../Assets/logo.png';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import {
    OurStory,
    HomeScreen,
    AppScreen,
    Contact,
    ICareEyeCare,
    OurMembers,
    OurMission,
    OurPartners,
    EyeHealthUnderCovid19,
    PublicEyeCheckUp,
} from '../Screens';

const AppNavbar = () => {
    const [textColor, setTextColor] = useState(['Gold', 'Black', 'Black', 'Black', 'Black']);
    const [expanded, setExpanded] = useState(false);
    const { t, i18n } = useTranslation();

    const handleClick = (number) => {
        console.log(number);
        const colorArr = textColor;
        setTextColor(colorArr.map((value, index) => {
            if (index === number) return 'Gold';
            else return 'Black';
        }));
    }

    const handleLanguageSwitch = (lang) => {
        i18n.changeLanguage(lang);
    }

    return (
        <Router>
            {/* A collisple navibar */}
            <Navbar className="navbar-custom" sticky="top" expand="xl" collapseOnSelect expanded={expanded}>
                <Navbar.Brand className="navbar-brand">
                    <img src={logo} alt="Forsee logo" />
                    <h3>ForeSee</h3>
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
                {/* Navibar content and settings are below */}
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="justify-content-end" style={{ width: "90%" }} >
                        <Nav.Link onClick={() => handleClick(0)}>
                            <Link to="" className="navbar-text" style={{ color: textColor[0] }}>
                                {t('home')}
                            </Link>
                        </Nav.Link>
                        <NavDropdown title={<span style={{ color: textColor[1] }} className="navbar-text">{t('about')}</span>}
                            id="collasible-nav-dropdown"
                            onClick={() => handleClick(1)}
                        >
                            <NavDropdown.Item>
                                <Link to="/ourstory" className='navbar-text no-hover' style={{ fontSize: 'medium' }}>
                                    {t('our-story-title')}
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <Link to="/ourmembers" className='navbar-text no-hover' style={{ fontSize: 'medium' }}>
                                    {t('home_part4')}
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <Link to="/ourmission" className='navbar-text no-hover' style={{ fontSize: 'medium' }}>
                                    {t('our-mission-title')}
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <Link to="/ourpartners" className='navbar-text no-hover' style={{ fontSize: 'medium' }}>
                                    {t('our-partners-title')}
                                </Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={() => handleClick(2)}>
                            <Link to="/app" className="navbar-text" style={{ color: textColor[2] }}>{t('App')}</Link>
                        </Nav.Link>
                        <NavDropdown title={<span style={{ color: textColor[3] }} className="navbar-text">{t('event')}</span>}
                            id="collasible-nav-dropdown"
                            onClick={() => handleClick(3)}
                        >
                            <NavDropdown.Item>
                                <Link to="/ICareEyeCare" className='navbar-text no-hover' style={{ fontSize: 'medium' }}>
                                    {t('title1')}
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <Link to="/PublicEyeCheckUp" className='navbar-text no-hover' style={{ fontSize: 'medium' }}>
                                    {t('title2')}
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <Link to="/EyeHealthUnderCovid19" className='navbar-text no-hover' style={{ fontSize: 'medium' }}>
                                    {t('title3')}
                                </Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={() => handleClick(4)}>
                            <Link to="/contact" className="navbar-text" style={{ color: textColor[4] }}>{t('contact_us')}</Link>
                        </Nav.Link>
                        <NavDropdown title={<span style={{ color: textColor[3] }} className="navbar-text">{t('language')}</span>}
                            id="collasible-nav-dropdown"
                        >
                            <NavDropdown.Item onClick={() => handleLanguageSwitch('en')}>
                                English
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={() => handleLanguageSwitch('zh-CN')}>
                                简体中文
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={() => handleLanguageSwitch('zh-TW')}>
                                繁體中文
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Routes>
                <Route path="" element={<HomeScreen />} />
                <Route path="/app" element={<AppScreen />} />
                <Route path="/ourstory" element={<OurStory />} />
                <Route path="/ourmembers" element={<OurMembers />} />
                <Route path="/ourmission" element={<OurMission />} />
                <Route path="/ourpartners" element={<OurPartners />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/ICareEyeCare" element={<ICareEyeCare />} />
                <Route path="/PublicEyeCheckUp" element={<PublicEyeCheckUp />} />
                <Route path="/EyeHealthUnderCovid19" element={<EyeHealthUnderCovid19 />} />
            </Routes>
        </Router>
    );
}

export default AppNavbar;