import React from 'react';
import {Link} from 'react-router-dom';
import "./Home.css";
import { useTranslation } from 'react-i18next';
import green_background from "../Assets/green_background.jpeg";
import logo from "../Assets/logo.png";
import teampic from "../Assets/teampic.jpg";
import { useInView } from "react-intersection-observer";
import Slider2 from "../Components/Slider2";
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn } from 'mdb-react-ui-kit';

const HomeScreen = () => {
    const { t, i18n } = useTranslation();
    const { ref, inView } = useInView({
      /* Optional options */
      threshold: 0.4,
    });

    return (
        <div className="Home">
          <article className="Home__image" style={{ backgroundImage: `url(${green_background})` }}>
            <img src={logo} alt="logo" className="logo"/>
            <h1 className="Home__title">{t('Home__title')}</h1>
            <h1 className="Home__content">
              {t('Home__content1')}
              <br/>
              {t('Home__content2')}
            ​</h1>
            {/* remove home_follow at the moment cuz it is ordered to be disable by CEO */}
            {/* <h1 className="Home__follow">{t('Home__follow')}</h1> */}
          </article>
          {/* Import Slider2 */}
          <Slider2/>                           
          <Slider2 flipped={true} />  
          {/* Part 3 of Home Page */}
          <br />
          <br />
          <div className="joinus">
            <h1 className="joinus_title">{t('home_part3')}</h1>
            <br />
            <div className='cols' style={{display: 'flex', flexWrap:'wrap', gap: '5%', justifyContent: 'center'}}>
                <MDBCard>
                  <MDBCardBody style={{backgroundColor:'#f2f2f2', height: '100%', alignItems: 'stretch', width:'30rem'}}>
                    <div className="logos" style={{display: 'flex', padding:'2px 2px'}}>
                      <img src={logo} alt="logo" className="cardLogo"/>
                      <div classname="texts" style={{padding:'0px 15px'}}>
                        <MDBCardTitle style={{color:'#437e5b'}}>{t('event_01')}</MDBCardTitle>
                        <MDBCardText>{t('event_01_description')}</MDBCardText>
                        <iframe 
                          width="300" 
                          height='auto'
                          src="https://www.youtube.com/embed/J7hWTKmZ6G8" 
                          title="YouTube video player" 
                          frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                        <MDBBtn href='https://www.youtube.com/channel/UCXjkhENQuYXNIqz9AdKVZBw'>{t('WATCH_VIDEOS')}</MDBBtn>
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
                <MDBCard>
                  <MDBCardBody style={{backgroundColor:'#f2f2f2', height: '100%', alignItems: 'stretch', width: '30rem'}}>
                    <div className="logos" style={{display: 'flex', padding:'2px 2px'}}>
                      <img src={logo} alt="logo" className="cardLogo"/>
                      <div classname="texts" style={{padding:'0px 15px'}}>
                        <MDBCardTitle style={{color:'#437e5b'}}>{t('event_02')}</MDBCardTitle>
                        <MDBCardText>{t('event_02_description')}</MDBCardText>
                        {/*styline*/}
                        <div className="embed" style={{display:"flex", gap: '5%'}}>
                          <iframe src="https://www.instagram.com/p/CcISbPIh7VA/embed" width="175" height="150" frameborder="0" scrolling="yes" allowtransparency="true" />
                          <iframe src="https://www.instagram.com/p/Cc-PG8RBhjS/embed" width="175" height="150" frameborder="0" scrolling="yes" allowtransparency="true" />
                          {/* <FacebookProvider appId="">
                            <EmbeddedPost href="https://www.facebook.com/foreseehkust/photos/a.509567190822474/527161442396382/" width="175" height="150"/>
                          </FacebookProvider> */}
                        </div>
                        <MDBBtn href='https://linktr.ee/foresee_hk'>{t('learn_more')}</MDBBtn>
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
            </div>
          </div>

          {/* Part 4 Home Page */}
          <br />
          <br />
          <div className="ourTeam">
            <div className='our-team-title'>
                <h1>{t('home_part4')}</h1>
            </div>
            <div className='our-team-content'>
                <img src={teampic} alt="teampicture" />
                <p>
                  {t('0401')}
                  <br/> <br/>
                  {t('0402')}
                  <br/> <br/>
                  <br/> {/* styling */}
                  <Link to='/ourmembers' style={{textDecoration: 'none', color: '#437e5b'}}>{t('learn_more')}</Link>
                  <br/>
                </p>
            </div>
          </div>
        </div>
   
    );
};

export default HomeScreen;
